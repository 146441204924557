import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaFileInvoice, FaUsers, FaPen, FaPlus, FaSearch, FaThumbsUp, FaThumbsDown, FaUpload, FaList, FaTable, FaBackward, FaEye } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import Alert from '../../../components/Alert';
import FormButton from '../../../components/FormButton';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import ParticipantesConfirmados from '../../../components/ParticipantesConfirmados';
import { data, dataHora } from '../../../util/util';

export default function PainelEventoParticipante({ match }) {
    let { search, state } = useLocation();
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [eventoParticipantes, setEventoParticipantes] = useState([]);
    const [evento, setEvento] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const operador = state?.operador ?? null;

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });

        //busca dados do evento
        api({
            method: 'get',
            action: '/evento',
            token: token,
            params: {
                ...{ id: id }
            }
        })
            .then(response => {
                var evento = response.data.data[0];

                if (filtro.datalista === undefined || filtro.datalista === null) {
                    if (evento.dataLista === undefined || evento.dataLista === null) {
                        //se nao tiver dataLista usa data inicial do evento
                        filtro.datalista = data(evento.data);
                        filtro.nomeDatalista = data(evento.data);
                    } else {
                        //se tiver dataLista usa dataLista
                        filtro.datalista = evento.dataLista;
                        filtro.nomeDatalista = evento.dataLista;
                    }
                }

                setEvento(evento);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            });

        var buscarParticipantes = true;

        //se for perfil listarsvp
        //e nao tiver escrito nada no nome, não buscar nenhum participante
        if (['listarsvp'].includes(perfil)) {
            if ((filtro.nome ?? "") === "") { //se for em branco 
                buscarParticipantes = false;
            }
        }
        if (buscarParticipantes) {
            //busca participantes do evento
            api({
                method: 'get',
                action: '/participante',
                token: token,
                params: {
                    ...filtro,
                    ...{
                        evento: id,
                        tipoCadastro: "participante",
                        operador: operador,
                        ultimoHistorico: true,
                    }
                }
            })
                .then(response => {
                    setEventoParticipantes(response.data.data);
                }).catch(err => {
                    //console.log(err)
                });
        } else {
            setEventoParticipantes([]);
        }
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        [filtro.datalista]);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (['diretor', 'rsvp', 'operadorrsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<RiMailSendLine size={20} />}
                        onClick={
                            () => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Contrato?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarContrato',
                                                token: token,
                                                data: {
                                                    idParticipantes: ids
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' contratos enviados com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                        }
                        color="primary"> Enviar Contrato</IconButton > : null,
                (['diretor', 'rsvp', 'operadorrsvp'].includes(perfil)) ?
                    <FormButton
                        small={true}
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                        icon={<FaEye size={20} />}
                        color="primary"
                        data={{
                            token: token,
                            tipoCarta: "modelocartaconvite",
                            idParticipantes: ids //exemplo: "234523452345,324532453,23452345"
                        }}
                    >Visualizar Convite</FormButton> : null,
                (['diretor', 'rsvp', 'operadorrsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<RiMailSendLine size={20} />}
                        onClick={
                            () => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Convite?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaConvite',
                                                token: token,
                                                data: {
                                                    idParticipantes: ids
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                        }
                        color="primary"> Enviar Convite</IconButton > : null,
                (['diretor', 'rsvp', 'operadorrsvp'].includes(perfil)) ?
                    <FormButton
                        small={true}
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                        icon={<FaEye size={20} />}
                        color="primary"
                        data={{
                            token: token,
                            tipoCarta: "modelocarta",
                            idParticipantes: ids //exemplo: "234523452345,324532453,23452345"
                        }}
                    >Visualizar Informativa</FormButton> : null,
                (['diretor', 'rsvp', 'operadorrsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<RiMailSendLine size={20} />}
                        onClick={
                            () => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Informativa?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaInformativa',
                                                token: token,
                                                data: {
                                                    idParticipantes: ids
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                        }
                        color="primary"> Enviar Informativa</IconButton > : null,
                (['diretor', 'rsvp'].includes(perfil)) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={
                            () => {
                                Alert({
                                    icon: "error",
                                    confirmButton: "Sair",
                                    denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                    title: "Excluir",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isDenied) {
                                            api({
                                                method: 'delete',
                                                action: '/participantes',
                                                token: token,
                                                data: {
                                                    idParticipantes: ids
                                                }
                                            })
                                                .then(function (response) {
                                                    buscar();
                                                    //alert("Evento cancelado com sucesso!");
                                                })
                                                .catch(function (error) {
                                                    //alert(error)
                                                });
                                        }
                                    }
                                });
                            }
                        }
                        color="primary"> Excluir</IconButton > : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['listarsvp'].includes(perfil) ? <NavItem to="/evento" icon={<FaBackward size={20} />} color="outline-dark">Eventos</NavItem> : null}
                    {(['listarsvp'].includes(perfil)) ?
                        <NavItem
                            to={{
                                pathname: "/cadastroEventoParticipanteLista",
                                state: {
                                    evento: id
                                }
                            }}
                            icon={<FaPlus size={20} />}
                            color="primary">Novo Participante</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/cadastroEventoParticipante",
                                state: {
                                    evento: id
                                }
                            }}
                            icon={<FaPlus size={20} />}
                            color="primary">Novo Participante</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoImportarParticipante/" + id,
                                state: {
                                    nomeEvento: evento.nome
                                }
                            }}
                            icon={<FaUpload size={20} />}
                            color="outline-dark">Importar Participantes</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoImportarAereo/" + id,
                                state: {
                                    nomeEvento: evento.nome
                                }
                            }}
                            icon={<FaUpload size={20} />}
                            color="outline-dark">Importar Aéreos Emitidos</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoImportarHospedagem/" + id,
                                state: {
                                    nomeEvento: evento.nome
                                }
                            }}
                            icon={<FaUpload size={20} />}
                            color="outline-dark">Importar Hospedagens</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoImportarInscricoes/" + id,
                                state: {
                                    nomeEvento: evento.nome
                                }
                            }}
                            icon={<FaUpload size={20} />}
                            color="outline-dark">Importar Inscrições</NavItem> : null}
                </ul>
                <ul className="nav">
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoVincularParticipanteOperador/" + id,
                                state: {
                                    nomeEvento: evento.nome
                                }
                            }}
                            icon={<FaUsers size={20} />}
                            color="outline-dark">Vincular Participantes aos Operadores</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem
                            to={{
                                pathname: "/eventoRelatorioParticipante/" + id,
                            }}
                            icon={<FaTable size={20} />}
                            color="outline-dark">Relatorio de Participantes</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ? <NavItem
                        to={{
                            pathname: "/relatorioListaPresenca/" + id,
                            state: {
                                nomeEvento: evento.nome
                            }
                        }}
                        icon={<FaFileInvoice size={20} />}
                        color="outline-dark">Lista de Presença</NavItem> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <FormButton
                            style={{ marginLeft: "10px" }}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/evento/exportarParticipanteAereo"}
                            icon={<FaTable size={20} />}
                            color="outline-dark"
                            data={{
                                token: localStorage.getItem('token'),
                                idEvento: id
                            }}
                        >Planilha Emissão Aérea</FormButton> : null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <FormButton
                            style={{ marginLeft: "10px" }}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/evento/exportarParticipanteRoomingList"}
                            icon={<FaTable size={20} />}
                            color="outline-dark"
                            data={{
                                token: localStorage.getItem('token'),
                                idEvento: id
                            }}
                        >Planilha Rooming List</FormButton> : null}
                    {['diretor', 'rsvp'].includes(perfil) ? <NavItem
                        to={{
                            pathname: "/cadastroEventoRsvp/" + id,
                        }}
                        icon={<FaPen size={20} />}
                        color="outline-dark">Dados RSVP</NavItem> : null}
                </ul>
                <div className="page-title">
                    <h3>{evento.nome}</h3>
                </div>
                {['listarsvp'].includes(perfil) ? <ParticipantesConfirmados evento={id} datalista={filtro.datalista ?? ""} /> : null}

                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Data</label>
                                                        <ReactSelect
                                                            id="datalista"
                                                            value={{ value: filtro.datalista || null, label: filtro.nomeDatalista || null }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "datalista": e.value, "nomeDatalista": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento/datas/' + id,
                                                                    token: token,
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro,
                                                                                label: registro
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label>Participante</label>
                                                            <ReactSelect
                                                                id="participante"
                                                                value={{ value: filtro.idParticipante || null, label: filtro.nomeParticipante || null }}
                                                                onChange={(e) => {
                                                                    filtro.idParticipante = e.value;
                                                                    filtro.nomeParticipante = e.label;
                                                                    setFiltro({ ...filtro });
                                                                    setTimeout(() => {
                                                                        buscar();
                                                                    }, 500);
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/participante',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            evento: id,
                                                                            tipoCadastro: "participante",
                                                                            situacaoRegistro: "ativo",
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map((registro) => {
                                                                                var label = registro.codigo +
                                                                                    ' - ' + registro.nome +
                                                                                    (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                    (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                    (registro.nomefantasia ? ' - ' + registro.nomefantasia : '') +
                                                                                    (registro.evento ? ' | ' + registro.evento.nome : '');
                                                                                return {
                                                                                    value: registro.id,
                                                                                    label: label
                                                                                };
                                                                            });
                                                                            return [
                                                                                { value: null, label: 'Todos' },
                                                                                ...options
                                                                            ];
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input autoFocus type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do evento"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Participante</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {!['listarsvp'].includes(perfil) ?
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Carta Convite</label>
                                                            <ReactSelect
                                                                value={{ value: filtro.situacaocartaconvite, label: filtro.nomeSituacaocartaconvite }}
                                                                onChange={(e) => {
                                                                    filtro.situacaocartaconvite = e.value;
                                                                    filtro.nomeSituacaocartaconvite = e.label;
                                                                    setFiltro({ ...filtro });
                                                                    setTimeout(() => {
                                                                        buscar();
                                                                    }, 500);
                                                                }}
                                                                options={[
                                                                    { value: '', label: 'Todas' },
                                                                    { value: 'confirmada', label: 'Confirmada' },
                                                                    { value: 'naoconfirmada', label: 'Não Confirmada' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Carta Informativa</label>
                                                            <ReactSelect
                                                                value={{ value: filtro.situacaocartainformativa, label: filtro.nomeSituacaocartainformativa }}
                                                                onChange={(e) => {
                                                                    filtro.situacaocartainformativa = e.value;
                                                                    filtro.nomeSituacaocartainformativa = e.label;
                                                                    setFiltro({ ...filtro });
                                                                    setTimeout(() => {
                                                                        buscar();
                                                                    }, 500);
                                                                }}
                                                                options={[
                                                                    { value: '', label: 'Todas' },
                                                                    { value: 'confirmada', label: 'Confirmada' },
                                                                    { value: 'naoconfirmada', label: 'Não Confirmada' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            title={tableSelect.selectedRows?.length > 0 ? "Participantes (" + tableSelect.selectedRows?.length + ")" : "Participantes"}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={!['listarsvp'].includes(perfil)}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent={['listarsvp'].includes(perfil) ? "Busque para listar" : "Nenhum registro"}
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        var alterarDiretor = null;
                                                        if (['diretor', 'rsvp'].includes(perfil)) {
                                                            alterarDiretor = <IconButton
                                                                small={true}
                                                                to={{
                                                                    pathname: "/cadastroEventoParticipante/" + row.id,
                                                                    state: {
                                                                        evento: id
                                                                    }
                                                                }}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Alterar</IconButton>;
                                                        }

                                                        var assinado = null;
                                                        var assinarParticipante = null;
                                                        if (['diretor', 'rsvp', 'listarsvp'].includes(perfil)) {
                                                            if (row.datalista?.imagemassinatura) {//ja tem assinatura
                                                                assinado = <span style={{ "scale": "0.8" }} className="badge bg-success ">Assinado</span>;
                                                            } else {
                                                                if (['listarsvp'].includes(perfil)) {
                                                                    assinarParticipante = <IconButton
                                                                        small={true}
                                                                        to={{
                                                                            pathname: "/cadastroEventoParticipanteAssinatura/" + row.id + "?datalista=" + filtro.datalista,
                                                                        }}
                                                                        icon={<FaPen size={20} />}
                                                                        color="primary">Assinar</IconButton>;
                                                                }
                                                            }
                                                        }

                                                        var alterarOperador = null;
                                                        if (['operadorrsvp'].includes(perfil)) {
                                                            alterarOperador = <IconButton
                                                                small={true}
                                                                to={{
                                                                    pathname: "/cadastroEventoParticipanteOperador/",
                                                                    search: new URLSearchParams({
                                                                        idCliente: row.id,
                                                                        nomeCliente: row.nome,
                                                                    }).toString()
                                                                }}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Alterar</IconButton>;
                                                        }
                                                        return <div>
                                                            {alterarDiretor}
                                                            {assinado}
                                                            {assinarParticipante}
                                                            {alterarOperador}
                                                        </div>;
                                                    },
                                                    width: "120px",
                                                },
                                                ...(
                                                    ['listarsvp'].includes(perfil) === false ?
                                                        [{
                                                            name: 'Convite',
                                                            selector: row => row.datacartaconvite ?? "",
                                                            sortable: true,
                                                            width: "110px",
                                                            cell: (row) => {
                                                                var cartaConviteAssinada = null;
                                                                if (row.imagemassinatura) {//ja tem assinatura
                                                                    cartaConviteAssinada = <span className="badge bg-info">Assinado</span>;
                                                                }

                                                                var mostrardatacartaconvite = row.datacartaconvite ?
                                                                    <span
                                                                        title={"Carta convite enviada em: " + dataHora(row.datacartaconvite)}
                                                                        className="badge bg-info">
                                                                        {dataHora(row.datacartaconvite)}
                                                                    </span>
                                                                    : null;

                                                                var mostrardatacartaassinada = row.datacartaassinada ?
                                                                    <span
                                                                        title={"Carta convite confirmada em: " + data(row.datacartaassinada)}
                                                                        className="badge bg-success">
                                                                        {data(row.datacartaassinada)}
                                                                    </span>
                                                                    : null;

                                                                return <div style={{ "scale": "0.8" }}>
                                                                    {mostrardatacartaconvite}
                                                                    {mostrardatacartaassinada}
                                                                    {cartaConviteAssinada}
                                                                </div>
                                                            },
                                                        },
                                                        {
                                                            name: 'Informativa',
                                                            selector: row => row.datacartainformativa ?? "",
                                                            sortable: true,
                                                            width: "110px",
                                                            cell: (row) => {
                                                                var mostrardatacartainformativa = row.datacartainformativa ?
                                                                    <span
                                                                        title={"Carta informativa enviada em: " + dataHora(row.datacartainformativa)}
                                                                        className="badge bg-info">
                                                                        {dataHora(row.datacartainformativa)}
                                                                    </span>
                                                                    : null;

                                                                var mostrardataconfirmacaocartainformativa = row.dataconfirmacaocartainformativa ?
                                                                    <span
                                                                        title={"Carta informativa confirmada em: " + data(row.dataconfirmacaocartainformativa)}
                                                                        className="badge bg-success">
                                                                        {data(row.dataconfirmacaocartainformativa)}
                                                                    </span>
                                                                    : null;

                                                                return <div style={{ "scale": "0.8" }}>
                                                                    {mostrardatacartainformativa}
                                                                    {mostrardataconfirmacaocartainformativa}
                                                                </div>
                                                            },
                                                        },
                                                        ] : []
                                                ),
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        if (['listarsvp'].includes(perfil)) {
                                                            return <div>{row.nome}</div>
                                                        } else {
                                                            return <div>{row.codigo}-{row.nome} - {row.cnpj}{row.cpf}</div>
                                                        }
                                                    },
                                                },
                                                ...(
                                                    ['rsvp', 'listarsvp'].includes(perfil) === true ?
                                                        [
                                                            {
                                                                name: 'CRM',
                                                                width: "110px",
                                                                cell: (row) => {
                                                                    return <div>{row.crm}</div>
                                                                },
                                                            },
                                                        ] : []
                                                ),
                                                ...(
                                                    ['listarsvp'].includes(perfil) === false ?
                                                        [{
                                                            name: 'Município',
                                                            selector: row => row.enderecomunicipio?.nome ?? "",
                                                            sortable: true,
                                                            width: "120px",
                                                            cell: (row) => {
                                                                return row.enderecomunicipio ? row.enderecomunicipio.nome + '-' + row.enderecomunicipio.uf : "";
                                                            },
                                                        },
                                                        {
                                                            name: 'Status',
                                                            selector: row => row.situacaoevento?.nome ?? "",
                                                            sortable: true,
                                                            width: "200px",
                                                            cell: (row) => {
                                                                return row.situacaoevento ?
                                                                    <span className="badge" style={{ fontSize: "8px", backgroundColor: row.situacaoevento.cor }} >{row.situacaoevento.nome}</span> : "";
                                                            },
                                                        },
                                                        {
                                                            name: 'Data',
                                                            width: "110px",
                                                            cell: (row) => {
                                                                return row.participantehistoricosituacao.length > 0 ? moment(row.participantehistoricosituacao[0].created_at).format("DD/MM/YYYY HH:mm:ss") : "";
                                                            },
                                                        },
                                                        {
                                                            name: 'Operador',
                                                            width: "100px",
                                                            cell: (row) => {
                                                                if (row.participantehistoricosituacao.length > 0) {
                                                                    if (row.participantehistoricosituacao[0].operador?.nome !== undefined) {
                                                                        return row.participantehistoricosituacao[0].operador.nome;
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        ] : []
                                                ),
                                            ]}
                                            data={eventoParticipantes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}
